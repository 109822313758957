import { useRef } from 'react';
import key from 'weak-key';
import isEmpty from 'lodash.isempty';
import { Container, Row, Col, CarouselSSRWrapper } from '@geberit/gdds';

// components
import TeaserElement from './teaser-element';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { getSectionId } from 'utils/get-section-id';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useThemeName } from 'utils/hooks/use-theme';

// styles
import styles from './teaser.module.scss';

// types
import { ITile } from './insights-teaser.types';
import { useIsMobile } from 'components/App/SizeProvider';

const mapSizes = {
  small: 41.67,
  medium: 58.33,
};

export interface InsightsTeaserProps {
  colorVariant: string;
  emphasize: string;
  link: Record<string, any>;
  title?: string;
  subtitle?: string;
  tiles: ITile[];
  contentIndex?: number;
}

export function InsightsTeaser({
  colorVariant,
  emphasize,
  link,
  title,
  subtitle,
  tiles,
  contentIndex,
}: Readonly<InsightsTeaserProps>) {
  const isMobile = useIsMobile();
  const sizes = useRef<('small' | 'medium' | 'large')[]>([]);
  const themeName = useThemeName();

  if (isEmpty(tiles) || tiles?.length <= 1) return null;

  const checkSize = (type: string, i: number) => {
    let size = type === 'large' ? 'medium' : type;

    if (size === 'medium') {
      const prevType = tiles?.[i - 1]?.type;
      const prevCalcSize = sizes.current[i - 1];

      size =
        (prevType === 'medium' || prevType === 'large') && prevCalcSize === 'medium'
          ? 'small'
          : size;
    }
    sizes.current = [...sizes.current, size];

    return size;
  };

  const renderWrapper = (children) => {
    if (!isMobile && tiles.length > 2) {
      const slidesWidth = sizes.current.map((size) => mapSizes[size]);

      return (
        <CarouselSSRWrapper
          responsiveLayout={{
            small: { slides: 0, slidesToMove: 1 },
            medium: { slides: 2, slidesToMove: 1 },
            large: { slides: 2, slidesToMove: 1 },
          }}
          hideButtons={isMobile}
          slidesWidth={slidesWidth}
          supportMouse
        >
          {children}
        </CarouselSSRWrapper>
      );
    }

    return <Row>{children}</Row>;
  };

  const hasHeadline = title || subtitle;
  const hasLink = link?.target;

  return (
    <div
      className={classNameBuilder(styles.insightsTeaser, styles[`${colorVariant}Background`])}
      id={getSectionId(title ?? '', undefined, contentIndex)}
    >
      {/* headline and link */}
      {(hasHeadline || hasLink) && (
        <Container maxContentWidth="78rem">
          {hasHeadline && (
            <Row>
              <Col>
                <Headline
                  format={Formats.h1}
                  tag={Formats.h2}
                  title={title}
                  subtitle={subtitle}
                  titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h1)}
                  subtitleFontWeight={getFontWeight(
                    emphasize === 'subtitle',
                    themeName,
                    Formats.h1,
                  )}
                  titlePreviewId="#st_title"
                  subtitlePreviewId="#st_subtitle"
                />
              </Col>
            </Row>
          )}
          {hasLink && (
            <Row>
              <Col>
                <div className={styles.linkWrapper}>
                  <CmsLink
                    link={link}
                    className={styles.link}
                    standardFontSize={false}
                    alignByContent="left"
                  />
                </div>
              </Col>
            </Row>
          )}
        </Container>
      )}

      {/* carousel */}
      <Container maxContentWidth={tiles.length > 2 ? '84.615rem' : '78rem'}>
        <Row>
          <Col className={styles.teaserWrapper}>
            {renderWrapper(
              tiles.map((tile, index) => {
                const curSize = checkSize(tile.type, index);
                const isLastItem = tiles.length === index + 1;

                return (
                  <TeaserElement
                    {...tile}
                    size={curSize}
                    isMobile={isMobile}
                    key={key(tile)}
                    isCarousel={!isMobile && tiles.length > 2}
                    isFirstItem={index === 0}
                    isLastItem={isLastItem}
                    isUppercase
                  />
                );
              }),
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
